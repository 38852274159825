import { GoCalendar } from 'react-icons/go'
import './index.scss'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { configs, url } from '../../Commens/Commens'
const Form = () => {
          const [servicesOptions, setServicesOptions] = useState([])
          const [fullName, setFullName] = useState('')
          const [mobileNumber, setMobileNumber] = useState('')
          const [services, setServices] = useState('')
          const [frequency, setFrequency] = useState('')
          const [duration, setDuration] = useState('')
          const [date, setDate] = useState('')
          const [timeTo, setTimeTo] = useState('')
          const [success, setSuccess] = useState(false)


          useEffect(() => {
                    axios.get(url + 'categories?parent=-1', configs).then(response => {
                              const data = (response.data.data)
                              setServicesOptions(data);
                    }).catch(error => { console.log(error) })
          }, []);

          const handleSubmit = () => {
                    if (fullName.trim('').length < 6 || mobileNumber.trim('').length < 6 || !services || !frequency || !duration || !date || !timeTo) {
                              console.log('check your data')
                              return
                    }
                    console.log(timeTo.toLocaleString('en-US', { hour: 'numeric', hour12: true }))
                    let data = new FormData();
                    data.append('name', fullName);
                    data.append('phone', mobileNumber);
                    data.append('service', services);
                    data.append('duration', duration);
                    data.append('frequency', frequency);
                    data.append('date', date + ' ' + timeTo.toLocaleString('en-US', { hour: 'numeric', hour12: true }));
                    axios.post(url + `inquiry`, data, configs).then(response => {
                              console.log(response.data)
                              setFullName('')
                              setMobileNumber('')
                              setServices('')
                              setFrequency('')
                              setDuration('')
                              setDate('')
                              setTimeTo('')
                              setSuccess(true)
                              setTimeout(()=>{
                                setSuccess(false)
                              },5000)
                    }).catch(error => {
                              console.log(error.message)
                    })
          }
          return (
                    <div className="form" id='Book'>
                              <div className="container mx-auto">
                                        <div className='Form mt-24 py-16 px-8 rounded-xl'>
                                                  <div className="row flex items-center justify-center flex-wrap">
                                                            <div className="w-full md:w-1/2 lg:w-1/3 text-left p-4 relative">
                                                                      <label className="text-white text-3xl " htmlFor="FullName">Full Name</label>
                                                                      <input onChange={(e) => setFullName(e.target.value)} value={fullName} placeholder='Full Name' autoComplete="true" id="FullName" type="text" className="block w-full px-4 py-4 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md " />

                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/3 text-left p-4 relative">
                                                                      <label className="text-white text-3xl " htmlFor="mobile"> Mobile Number</label>
                                                                      <input type='tel' onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} placeholder='Mobile Number' autoComplete="true" id="mobile" className="block w-full px-4 py-4 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md " />
                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/3 text-left p-4 relative">
                                                                      <label className="text-white text-3xl " htmlFor="Services">Services</label>
                                                                      <select onChange={(e) => setServices(e.target.value)} value={services} id="Services" autoComplete="true" className="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md ">
                                                                                <option value="" className='text-gray'>Choose Services </option>
                                                                                {
                                                                                          servicesOptions.map((item, index) => (
                                                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                                          ))
                                                                                }
                                                                      </select>
                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/4 text-left p-4 relative">
                                                                      <label className="text-white text-3xl " htmlFor="Frequency">Frequency</label>
                                                                      <select onChange={(e) => setFrequency(e.target.value)} value={frequency} id="Frequency" autoComplete="true" className="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md ">
                                                                                <option value="" className='text-gray'>Choose Frequency </option>
                                                                                <option value="1"> One-time  </option>
                                                                                <option value="2"> Bi-weekly  </option>
                                                                                <option value="3"> Weekly  </option>
                                                                      </select>
                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/4 text-left p-4">
                                                                      <label className="text-white text-3xl " htmlFor="Duration">Duration</label>
                                                                      <select onChange={(e) => setDuration(e.target.value)} value={duration} id="Duration" autoComplete="true" className="block w-full px-4 py-3 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md ">
                                                                                <option value="" className='text-gray'>Choose Duration </option>
                                                                                <option value="1">1 hour</option>
                                                                                <option value="2">2 hours</option>
                                                                                <option value="3">3 hours</option>
                                                                                <option value="4">4 hours</option>
                                                                                <option value="5">5 hours</option>
                                                                                <option value="+6">+6 hours</option>
                                                                      </select>
                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/4 text-left p-4">
                                                                      <label className="text-white text-3xl " htmlFor="Date">Date</label>
                                                                      <input onChange={(e) => setDate(e.target.value)} value={date} autoComplete="true" id="Date" type="date" className="block w-full px-4 py-4 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md " />

                                                            </div>
                                                            <div className="w-full md:w-1/2 lg:w-1/4 text-left p-4">
                                                                      <label className="text-white text-3xl " htmlFor="Time">Time</label>
                                                                      <div className="flex items-center justify-center">
                                                                                <input onChange={(e) => setTimeTo(e.target.value)} value={timeTo} autoComplete="true" id="Time" type="time" className="block w-full px-4 py-4 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md " />
                                                                                {/* <span className='font-bold mx-2 text-white text-3xl '>To</span>
                                                                                <input onChange={(e) => setTimeFrom(e.target.value)} value={timeFrom} autoComplete="true" id="Time" type="time" className="block w-full px-4 py-4 mt-2 text-gray-700 bg-white border border-white text-3xl rounded-md " /> */}
                                                                      </div>
                                                            </div>
                                                            {success && <h3 className='font-bold text-white w-full text-4xl my-4 text-center'>Your message has been sent</h3>}
                                                            <button className="mainBtn mt-12" onClick={handleSubmit} >
                                                                <GoCalendar className=" text-3xl mx-4" />
                                                                Book Now
                                                            </button>
                                                  </div>
                                        </div>
                              </div>
                    </div>
          )
}

export default Form