import React from 'react'
import Layout from '../Component/Layout'
import Banner from '../Component/HomeSections/Banner/Banner'
import BestServices from '../Component/HomeSections/BestServices/BestServices'
import OurServices from '../Component/OurServices/OurServices'
import WhyDangiCleaners from '../Component/WhyDangiCleaners/WhyDangiCleaners'
import Form from '../Component/Form/Form'

const Home = () => {

  return (
    <Layout>
      <Banner />
      <BestServices />
      <OurServices />
      <WhyDangiCleaners />
      <Form /> 
    </Layout>
  )
  
}

export default Home