import { Link } from 'react-router-dom'
import Card from '../../Component/UI/Card/Card'

const Service = (props) => {
          return (
                    <div className="p-2 w-1/2 xl:w-1/4 ">
                        <Card className="lg:w-full hover:bg-mainColor hover:text-white transition-all duration-700 my-4">
                              <Link to={`/Category/${props.id}`} >
                                        <img src={props.image} alt="" className='w-12' />
                                        <h3 className='text-3xl mt-4 font-medium text-subColor text-ellipsis overflow-hidden w-full whitespace-nowrap'>{props.name}</h3>
                                        <p className='text-2xl leading-10 hover:text-white  text-gray'> {props.description}</p>
                              </Link>
                    </Card>
                    </div>
          )
}

export default Service