import { Link } from 'react-router-dom'
import v_logo from '../../img/v_logo.svg'
import wave from '../../img/footer.jpg'
import {   AiOutlineWhatsApp, AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai'
import {  FaTiktok } from 'react-icons/fa'
import { FiFacebook } from 'react-icons/fi'
import { BiPhoneCall } from 'react-icons/bi'




const Footer = () => {
  return (

    <>
      <footer className="bg-mainColor mt-24 pt-48 lg:pt-96 relative overflow-hidden">
        <img src={wave} alt="" className='absolute top-0 left-0 min-w-full ' />
        <div className="container mx-auto relative pb-24">
          <div className="row flex items-start justify-center flex-wrap text-center md:text-left">
            <div className="w-full mt-8 lg:w-1/5 ltr:pr-2">
              <img src={v_logo} alt=""  className='mx-auto mb-4 w-48 lg:w-auto'/>
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>PCR Test/abs Test</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Furniture cleaning</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Pest control</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Women’s salon</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Men’s salon</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Women spa/Men’s spa</Link>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Health</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Personal trainer</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Baby setting</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>home cleaning</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>pet grooming</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Deep cleaning</Link>
                </li>
              </ul>
            </div>
            <div className="w-full md:w-1/3 mt-8 lg:w-1/5 ltr:pr-2">
              <ul>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Packers and movers</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Car wash at home</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Handyman & maintenance</Link>
                </li>
                <li className='mb-4 text-3xl text-white'>
                  <Link to='/'>Laundry and dry cleaning</Link>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-1/5 text-center lg:text-left flex flex-wrap items-center mt-8 ">
              <ul className='w-full mt-6'>
                <li className='w-full mb-4 text-3xl text-white text-center lg:text-left'>
                  <a href='tel:0500000000000' className='w-full flex items-center  justify-center lg:justify-start '><BiPhoneCall className='mr-4' /> +971 50 000 0000</a>
                </li>
                <li className='w-full mb-4 text-3xl text-white text-center lg:text-left'>
                  <a href='email:0500000000000' className='w-full flex items-center  justify-center lg:justify-start '><AiOutlineMail className='mr-4' />  info@dangicleaners.com</a>
                </li>
              </ul>
              <ul className='w-full flex items-center justify-center lg:justify-start mt-4'>
                <li className='pr-4 text-5xl text-white'>
                  <a href="http://www.facebook.com"><AiOutlineInstagram /></a> </li>
                <li className='pr-4 text-5xl text-white'>
                  <a href="http://www.facebook.com"><AiOutlineWhatsApp /></a></li>
                <li className='pr-4 text-5xl text-white'>
                  <a href="http://www.facebook.com"><FaTiktok /></a></li>
                <li className='pr-4 text-5xl text-white'>
                  <a href="http://www.facebook.com"><FiFacebook /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="w-full text-center">
        <p className=' text-2xl  text-black my-8'>All rights reserved to Dangicleaners  | Designed by <a href='https://www.brainclickads.com' target='_blank' rel="noreferrer"  className='font-bold text-mainColor'>  Brain Click  </a>2022</p>
      </div>
    </>
  )
}

export default Footer