import './index.scss'

const index = (props) => {
  const classes = 'OutlineBtn flex items-center justify-center p-4 rounded-lg text-3xl relative  ' + props.className
  const buttonContent = 'p-2  rounded-xl CoverClass ' + props.Classes
  // bg-white border-2 border-mainColor
  return (
    <div className="flex justify-start items-center mb-8 w-full">
      <div className={buttonContent}>
        <button className={classes}>
          {props.children}
        </button>
      </div>
    </div>
  )
}

export default index