import handfree from '../../img/handfree.png'
import services from '../../img/services.png'
import star from '../../img/star.png'

const WhyDangiCleaners = () => {
          return (
                    <div id="BestServices" className='mt-24 xl:mt-72'>
                              <div className="container mx-auto">
                                        <div className="row flex items-center justify-center flex-wrap">
                                                  <div className="w-full md:w-1/2 text-center">
                                                            <h3 className='text-4xl mb-6 font-medium text-subColor'>Why Dangi Cleaners?</h3>
                                                            <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                  </div>
                                                  <div className="row flex items-center justify-center flex-wrap relative overflow-hidden z-20">
                                                            <img src={star} alt="" className='absolute left-0 top-0 -z-10' />

                                                            <div className="w-full md:w-4/12 relative">
                                                                      <div className="text-center mb-16 p-2 lg:p-16">
                                                                                <img src={handfree} alt="" className='w-28 mb-8 mx-auto' />
                                                                                <h3 className='text-4xl mb-6 font-medium text-subColor'>Why Dangi Cleaners?</h3>
                                                                                <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                                      </div>
                                                                      <div className="text-center mb-16 p-2 lg:p-16">
                                                                                <img src={handfree} alt="" className='w-28 mb-8 mx-auto' />
                                                                                <h3 className='text-4xl mb-6 font-medium text-subColor'>Why Dangi Cleaners?</h3>
                                                                                <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                                      </div>
                                                            </div>
                                                            <div className="w-full md:w-4/12">
                                                                      <div className="image p-8">
                                                                                <img src={services} alt="" className='w-10/12 mb-8 mx-auto' />
                                                                      </div>
                                                            </div>
                                                            <div className="w-full md:w-4/12 relative">
                                                                      <div className="text-center mb-16 p-2 lg:p-16">
                                                                                <img src={handfree} alt="" className='w-28 mb-8 mx-auto' />
                                                                                <h3 className='text-4xl mb-6 font-medium text-subColor'>Why Dangi Cleaners?</h3>
                                                                                <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                                      </div>
                                                                      <div className="text-center mb-16 p-2 lg:p-16 ">
                                                                                <img src={handfree} alt="" className='w-28 mb-8 mx-auto' />
                                                                                <h3 className='text-4xl mb-6 font-medium text-subColor'>Why Dangi Cleaners?</h3>
                                                                                <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                                      </div>
                                                            </div>
                                                  </div>

                                        </div>
                              </div>
                    </div>
          )
}

export default WhyDangiCleaners