import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { configs, getBookingObj, getErrorInCard, setBookingObj, showLoading, updateCostCard, url } from '../../Commens/Commens';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiFillInfoCircle } from "react-icons/ai";
import logo2 from "../../img/fav2.png";

const DateTime = () => {
  const [questions, setQuestions] = useState();
  const [professionals, setProfessionals] = useState([]);
  const [val, setVal] = useState(1);
  const [professional_id, setProfessionalsId] = useState(-1);

  const { id } = useParams();



  useEffect(() => {
    const sendGetRequest = async () => {
      try {
        const resp = await axios.get(url + `categories/${id}`, configs);
        setQuestions(resp.data.data.params);

      } catch (err) {
        // Handle Error Here
        console.error(err);
      }
    };
    sendGetRequest();
  }, [])
  // var xx = new Date();
  // var currentDate = xx.toISOString().slice(0,10); 
  // document.getElementById('today').value = currentDate;

  function loadParamsObj(event) {

    const parameters = [];
    const day = document.getElementById('day').value
    var dt = new Date(day);
    const time = document.getElementById('time').value
    const date = day + ' ' + time + ':00';
    for (var j = 0; j < questions.length; j++) {
      var que = questions[j];
      var type = 'unknown'
      if (que.options.length === 0) {
        type = 'text'
      } else if (que.options.length === 2) {
        type = 'radio'
      } else {
        type = 'select'
      }
      var param = {}
      param.id = que.id;
      if (type === 'text') {
        param.value = document.getElementById('textareaId_' + param.id).value
      }
      if (type === 'select') {
        param.value = parseInt(document.getElementById('selectId_' + param.id).value)
      }
      if (type === 'radio') {
        var options = que.options;
        if (document.getElementById('p_option_' + options[0].id).checked) {
          param.value = options[0].id
        } else {
          param.value = options[1].id
        }
      }
      parameters.push(param)
    }

    const old = getBookingObj()
    old.parameters = parameters
    // old.professionals_count = parseInt(val)
    old.professionals_count = parseInt(document.getElementById('professionalsCount').value)
    if (old.professionals_count == 1) {
      old.professional_id = professional_id // read from girls 
    } else {
      old.professional_id = '' // read from girls 
      // console.log(old.professional_id)
    }
    old.date = date
    const data = old
    const configsPost = configs
    configsPost.data = data
    showLoading(true)
    axios.post(url + `calculate_price`, data, configsPost).then(response => { 
      if (response.status === 200) {
        const rep = response.data.data;
        updateCostCard(rep)
        showLoading(false)
      }

    }).catch(error => {
      console.log(error.message)
      const rep = error;
      getErrorInCard(rep)
      showLoading(false)
    })
    axios(url + `find_professionals/${dt.getDay()}/${time}/${id}`, data, configsPost
    ).then(response => {
      const professionals = response.data.data
      setProfessionals(professionals)

    })
    setBookingObj(old)
  }


  let que = null
  if (questions) {
    que = questions.map((item, index) => (
      <div className="form-group w-full my-4" key={index}>

        {item.options.length > 2 ?
          <div className="form-group w-full my-4 tt">
            <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Duration">  {item.description}  </label>
            <select onChange={loadParamsObj} id={'selectId_' + item.id} className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  ">
              {/* <select onChange={e => param.push({ 'value': e.target.value, 'id':  item.id })} className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  "> */}
              {/*  */}
              {
                item.options.map((innerItem, innerIndex) => (
                  <option key={innerIndex} value={innerItem.id}>{innerItem.name} </option>
                ))
              }
            </select>
          </div>
          : (item.options.length === 0 ?
            <div className="form-group w-full my-4 hidden">
              <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Duration">  {item.name}  </label>
              <textarea onChange={loadParamsObj} placeholder={item.description + '. . . '} name="notes" id={'textareaId_' + item.id} cols="30" rows="5" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  "></textarea>
            </div>
            :
            <div className="form-group w-full my-4">
              <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Duration"> {item.description}  </label>
              {
                item.options.map((innerItem, innerIndex) => (
                  <label key={innerIndex} className="radioBox"> {innerItem.name}
                    <input type="radio" name={`radio`} value={innerItem.id} id={'p_option_' + innerItem.id} onChange={loadParamsObj} />
                    <span className="checkmark"></span>
                  </label>
                ))
              }
            </div>)

        }
        {/* {item.options.length === 0 ?
          <div className="form-group w-full my-4">
            <textarea name="notes" id="" cols="30" rows="10" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  "></textarea>
          </div>
          : ''} */}

      </div>
    ))
  }


  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    padding: '50px',
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className="w-full" >

      <div className='row flex item-center justify-center flex-wrap mb-8'>
        <div className="w-full flex mb-4 rounded-xlmt-6 p-4 items-center justify-center flex-wrap">

        </div>
      </div>
      <h3 className='font-bold my-8 text-3xl'> Data and Time</h3>
      <div className="serviceTime">
        <div className='row flex item-center justify-center flex-wrap mb-8'>
          <div className="w-full flex mb-4 rounded-xlmt-6 p-4 items-center justify-center flex-wrap">
            <div className="row flex items-center justify-between gap-4 w-full flex-wrap">
              <div className="w-full flex items-center justify-between flex-wrap md:flex-nowrap md:gap-4">
                <div className="form-group w-full md:w-4/12 my-4">
                  <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="day">Date</label>
                  {/* <input type="date" name="" id="today" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " /> */}
                  <input onChange={loadParamsObj} type="date" name="" id="day" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />
                </div>
                <div className="form-group w-full md:w-4/12 my-4">
                  <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="time">Time form </label>
                  <input onChange={loadParamsObj} type="time" name="" id="time" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />
                </div>
                <div className="form-group w-full md:w-4/12 my-4">
                  <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="time">Professionals Count </label>
                  <select onChange={(e) => { setVal(e.target.value); loadParamsObj() }} id='professionalsCount' className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  ">
                    {/* <option value='null'>Choose  Count</option> */}
                    <option value='1'>1</option>
                    <option value='2'>2</option>
                    <option value='3'>3</option>
                    <option value='4'>4</option>
                    <option value='5+'>5+</option>
                  </select>
                </div>
              </div>
              {professionals.length > 0 && val == 1 ?
                <div className="w-full rounded-3xl" onChange={loadParamsObj}>
                  <Slider {...settings}>

                    <div className="p-1">
                      <div className={`professionalsCard relative overflow-hidden cursor-pointer ${professional_id == -1 ? 'border-4 border-mainColor rounded-xl' : 'border-2 border-gray'} `}>
                        <input className=' cursor-pointer absolute w-full h-full left-0 top-0 opacity-0' type="radio" value='-1' name='professional' onClick={(e) => { setProfessionalsId(e.target.value) }} />
                        <div className='overflow-hidden    shadow-lg '>
                          <div className="img h-72 flex items-center justify-center">
                            <img className="w-auto h-auto" src={logo2} alt="avatar" />
                          </div>
                          <div className={` py-5 text-center text-overflow  ${professional_id === '-1' ? 'bg-mainColor text-white ' : ' bg-white text-black'} `}>
                            <h3 className="block text-2xl font-bold  px-2 ">DANGI CLEANERS</h3>
                            <span className="text-xl text-gray-700   px-2"> Your Satisfaction is Our Top Priority​</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      professionals.map((item, index) => (
                        <div className="p-1" key={index} >
                          <div id={item.id} className={`professionalsCard  cursor-pointer mx-1 relative overflow-hidden ${professional_id == 'professional' + item.id ? 'bg-mainColor border-4 border-mainColor rounded-xl' : 'border-4 border-gray '} `}>
                            <input className='absolute  cursor-pointer w-full h-full left-0 top-0 opacity-0' type="radio" value={'professional' + item.id} name='professional' onClick={() => setProfessionalsId(item.id)} />
                            <div className=" bg-white shadow-lg ">
                              <div className="img h-72">
                                <img className="w-full h-full" src={item.profile_pic} alt="avatar" />
                              </div>

                              <div className={`py-5 text-center text-overflow ${professional_id == item.id ? 'bg-mainColor text-white' : 'false'} `}>
                                <h3 className="block text-2xl font-bold text-black px-2"> {item.name}</h3>
                                <span className="text-xl text-gray-700 px-2"> {item.details}</span>
                              </div>
                            </div>
                          </div>
                        </div>

                      ))
                    }

                  </Slider>
                </div>
                : (val === 1 ? <div className="w-full bg-lightGray rounded-3xl p-1 mb-8">
                  <div className="flex w-full  mx-auto overflow-hidden bg-white rounded-3xl ">
                    <div className="flex items-center justify-center w-36 bg-mainColor">
                      <AiFillInfoCircle className='text-6xl text-white ' />
                    </div>

                    <div className="px-4 py-4 -mx-3">
                      <div className="mx-3">
                        <span className="font-black text-subColor py-2 text-2xl">Alert</span>
                        <p className="text-2xl text-gray-600  py-2">Please select a date to show available professionals. </p>
                      </div>
                    </div>
                  </div>
                </div> : '')

              }

            </div>
            {que}

          </div>
        </div>
      </div>
    </div >
  )
}

export default DateTime