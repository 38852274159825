import axios from 'axios'
import React, { useState } from 'react'
import { configs, getBookingObj, setBookingObj, url } from '../../Commens/Commens'
import uae from '../../img/uae.png'
import hsFlag from '../../img/ng.png'

import { useNavigate } from 'react-router-dom';
import Error from '../UI/Alert/Error';
import Success from '../UI/Alert/Success';

const Phone = () => {
          const navigate = useNavigate();
          const [phoneNumber, setPhoneNumber] = useState('')
          const [error, setError] = useState(false)
          const [success, setSuccess] = useState(false)
          const [errorMessge, setErrorMessage] = useState([])
          const [successMessge, setSuccessMessage] = useState('')
          const name = useFormInput('');
          const email = useFormInput('');
          const gender = useFormInput('');
          const address1 = useFormInput('');
          const [otp, setOtp] = useState(false);
          const [signup, setSignup] = useState(false);
          const [number1, setnumber1] = useState('');
          const [number2, setnumber2] = useState('');
          const [number3, setnumber3] = useState('');
          const [number4, setnumber4] = useState('');
          const [number5, setnumber5] = useState('');
          const [phone, setPhone] = useState('')
            const countryJey = localStorage.i18nextLng

          // Payment 
          const [cardNumber, setCardNumber] = useState('');
          const [expMonth, setExpMonth] = useState('');
          const [expYear, setExpYear] = useState('');
          const [nameCustomer, setNameCustomer] = useState('');
          const [cvv, setCvv] = useState('');

          const old = getBookingObj()

          const handleSignup = (event) => {
                    event.preventDefault()
                    let data = new FormData();
                    data.append('phone', `${countryJey == 'ha' ? '+234' : '+971'}${phone}`);
                    data.append('email', email.value);
                    data.append('gender', gender.value);
                    data.append('address1', address1.value);
                    data.append('name', name.value);
                    data.append('longitude', old.location_value.lng);
                    data.append('latitude', old.location_value.lat);
                    data.append('city', 'dubai');
                    setPhoneNumber(phone)
                    axios.post(url + 'signup', data, configs).then(response => {
                              const token = response.data.data.token
                              localStorage.setItem('token', JSON.stringify(token));
                              setSuccessMessage(response.data.message)
                              setSuccess(true)
                              setTimeout(() => {
                                        setSuccess(false)
                              }, 2000);
                              setOtp(true)
                    }).catch(error => {
                              error = error.response.data.message
                              setError(true)
                              setErrorMessage(error)
                              setTimeout(() => {
                                        setError(false)
                              }, 2000);
                              console.log(error)
                    })
          }

          const handleSend = (event) => {
                    event.preventDefault()
                    let data = new FormData();
                    data.append('phone', `${countryJey == 'ha' ? '+234' : '+971'}${phone}`);
                    setPhoneNumber(phone)
                    axios.post(url + 'otp/request', data, configs).then(response => {
                              setOtp(true)
                    }).catch(error => {
                              console.log(error.response)
                    })
          }

          const opt = [number1, number2, number3, number4, number5]

          const handleVer = (event) => {
                    const otpString = opt.join('')
                    event.preventDefault()
                    let data = new FormData();
                    data.append('phone', `${countryJey == 'ha' ? '+234' : '+971'}${phone}`);
                    data.append('otp', otpString);
                    axios.post(url + 'otp/verify', data, configs).then(response => {
                              const data = response.data.data

                              if (data === null) {
                                        setSignup(true)
                              } else {
                                        const token = response.data.data.token
                                        localStorage.setItem('token', JSON.stringify(token));
                              }

                              setOtp(false)
                              setnumber1('')
                              setnumber2('')
                              setnumber3('')
                              setnumber4('')
                              setnumber5('')
                              // localStorage.setItem('user', JSON.stringify(response.data.data));
                              localStorage.setItem('user', response.data.data.name);

                    }).catch(error => {
                              console.log(error)
                    })
          }

          function handleBooking() {
                    const paymentObj = {};
                    paymentObj.card_no = cardNumber
                    paymentObj.exp_date_month = expMonth
                    paymentObj.exp_date_year = expYear
                    paymentObj.name = nameCustomer
                    paymentObj.cvv = cvv
                    old.payment = paymentObj
                    old.token = localStorage.token
                    setBookingObj(old)
                    const data = old
                    // const configsPost = configs
                    // configsPost.data = data

                    axios({
                              method: 'post', //you can set what request you want to be
                              url: url + `book`,
                              data: data,
                              headers: {
                                        'Accept': 'application/json',
                                        'lang': localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
                                        "Authorization": `Bearer ${localStorage.getItem('token').replace(/['"]+/g, '')}`
                              }
                    }).then(response => {
                              setSuccess(true)
                              setTimeout(() => {
                                        localStorage.removeItem('bookingObj');
                                        setSuccess(false)
                                        navigate('/ThankYou')
                                        localStorage.removeItem('token');
                                        localStorage.removeItem('user');

                              }, 2000);
                    }).catch(error => {
                              setErrorMessage(error.response.data.error)
                              setError(true)
                              setTimeout(() => {
                                        setError(false)
                              }, 5000);
                    })
          }


          return (
                    <div className=' relative overflow-hidden border-2 border-mainColor p-4 rounded-2xl mt-6'>

                              {error &&
                                        <div className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
                                                  {/* <Error> {errorMessge.map((item, index) => <div key={index}>{item}</div>)} </Error> */}
                                                  <Error> {errorMessge} </Error>
                                        </div>
                              }
                              {success &&
                                        <div className="w-screen h-screen fixed top-0 left-0 bg-greenLight z-50 flex items-center justify-center">
                                                  <Success successMessge={successMessge} />
                                        </div>
                              }
                              <>

                                        {!localStorage.token ?
                                                  <>
                                                            <h3 className='text-2xl font-bold my-6'>Log in or sign up</h3>
                                                            <hr className='bg-mainColor text-mainColor' />
                                                            {signup ?
                                                                      <>
                                                                                <h1 className='text-2xl font-bold my-6'> Sign Up </h1>

                                                                                <div className='form-group my-4'>
                                                                                          <label htmlFor="" className='text-2xl flex capitalize'>phone</label>
                                                                                          <input onChange={(e) => setPhone(e.target.value)} value={`${localStorage.i18nextLng == 'ha' ? '+234' : '+971'}${phone}`} disabled placeholder='Full name' autoComplete="true" id="mobile" type="text" className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-lightGray text-2xl cursor-not-allowed " />
                                                                                </div>
                                                                                <div className='form-group my-4'>
                                                                                          <label htmlFor="" className='text-2xl flex capitalize'>Full name</label>
                                                                                          <input {...name} placeholder='Full name' autoComplete="true" id="" type="text" className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                                </div>
                                                                                <div className='form-group my-4'>
                                                                                          <label htmlFor="" className='text-2xl flex capitalize'>email</label>
                                                                                          <input {...email} placeholder='Full name' autoComplete="true" id="" type="email" className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                                </div>
                                                                                <div className='form-group my-4'>
                                                                                          <label htmlFor="" className='text-2xl flex capitalize'>Full Address</label>
                                                                                          <input {...address1} placeholder='Full name' autoComplete="true" id="" type="text" className="border-2 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                                </div>
                                                                                <div className='form-group my-4'>
                                                                                          <label htmlFor="" className='text-2xl flex capitalize'>gender</label>
                                                                                          <div className="flex justify-between items-center text-xl">
                                                                                                    <div className="form-group w-full my-4">
                                                                                                              <label className="radioBox text-xl"> Male
                                                                                                                        <input {...gender} type="radio" name="gender" value='male' />
                                                                                                                        <span className="checkmark"></span>
                                                                                                              </label>
                                                                                                    </div>
                                                                                                    <div className="form-group w-full my-4">
                                                                                                              <label className="radioBox text-xl"> Female
                                                                                                                        <input {...gender} type="radio" name="gender" value='female' />
                                                                                                                        <span className="checkmark"></span>
                                                                                                              </label>
                                                                                                    </div>
                                                                                          </div>
                                                                                </div>

                                                                                <button onClick={handleSignup} className='bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl ' >Continue </button>
                                                                      </>
                                                                      :
                                                                      (otp ?
                                                                                <div className="otp w-full mt-8 flex-wrap">
                                                                                          <label htmlFor="" className='text-xl w-full my-4 flex capitalize'>enter the code that was sent to</label>
                                                                                          <p className='mb-6 text-2xl font-bold'>{localStorage.i18nextLng == 'ha' ? '+234' : '+971'}  {phoneNumber}</p>
                                                                                          <form className="relative gap-4 flex items-center justify-start  " id='otp'>
                                                                                                    <input type="tel" onChange={(event) => setnumber1(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                                                                                                    <input type="tel" onChange={(event) => setnumber2(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                                                                                                    <input type="tel" onChange={(event) => setnumber3(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                                                                                                    <input type="tel" onChange={(event) => setnumber4(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                                                                                                    <input type="tel" onChange={(event) => setnumber5(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='border-2 p-4 border-mainColor w-16 text-2xl text-center font-bold rounded-lg' />
                                                                                          </form>
                                                                                          <div className="flex justify-between mt-6">
                                                                                                    <button onClick={handleSend} className='my-2 font-bold text-2xl text-mainColor' >Resend Code </button>
                                                                                                    <button onClick={handleVer} className='bg-mainColor flex p-4 px-8 my-4 rounded-xl items-end  text-white font-bold text-2xl ' >Continue </button>
                                                                                          </div>
                                                                                </div>
                                                                                :
                                                                                <div className='form-group'>
                                                                                          <label htmlFor="" className='text-2xl my-4 flex capitalize'>your mobile number</label>
                                                                                          <div className=" relative overflow-hidden phoneNumber flex items-center justify-center  border-2 rounded-2xl pl-4 border-mainColor">
                                                                                                    <div className='flex items-center justify-center text-2xl px-6 py-4'>
                                                                                                              <img src={localStorage.i18nextLng == 'ha' ? hsFlag : uae}  alt="" className='w-8' />
                                                                                                              <span>{localStorage.i18nextLng == 'ha' ? '+234' : '+971'}</span>
                                                                                                    </div>
                                                                                                    <input onChange={(e) => setPhone(e.target.value)} value={phone} placeholder='Mobile Number' autoComplete="true" id="mobile" type="tel" className="block w-full px-4 mt-2 text-gray-700 bg-white border-l-2 border-mainColor text-2xl  " />
                                                                                                    <button onClick={handleSend} className='px-4 absolute -right-4  top-0 bg-mainColor z-50 h-full text-white font-bold text-2xl '>Send OTP </button>
                                                                                          </div>
                                                                                          <button onClick={handleSend} className='my-2 font-bold text-2xl text-mainColor' >Resend Code </button>

                                                                                </div>)}
                                                  </>
                                                  :
                                                  <div className="row flex items-center justify-between gap-4 w-full flex-wrap">
                                                            <div className="w-full flex items-center justify-between flex-wrap  md:flex-nowrap md:gap-4">
                                                                      <div className="form-group w-full md:w-4/12 my-4">
                                                                                <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="CardNumber">Card Number</label>
                                                                                {/* <input type="date" name="" id="today" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " /> */}
                                                                                <input onChange={(e) => setCardNumber(e.target.value)} value={cardNumber} placeholder='Card Number' type="text" name="" id="CardNumber" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                      </div>
                                                                      <div className="form-group w-full md:w-4/12 my-4">
                                                                                <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="CVV">CVV </label>
                                                                                <input onChange={(e) => setCvv(e.target.value)} value={cvv} placeholder='CVV' maxLength='3' type="text" name="" id="CVV" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                      </div>
                                                                      <div className="form-group w-full md:w-4/12 my-4">
                                                                                <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Date">Exp. Date </label>
                                                                                <div className="flex gap-4">
                                                                                          <input onChange={(e) => setExpMonth(e.target.value)} maxLength='2' value={expMonth} type="text" placeholder='MM' name="" id="Date" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />
                                                                                          <input onChange={(e) => setExpYear(e.target.value)} maxLength='4' value={expYear} type="text" placeholder='YYYY' name="" id="Date" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />

                                                                                </div>
                                                                      </div>
                                                                      <div className="form-group w-full md:w-4/12 my-4">
                                                                                <label className="text-black mb-4 flex  w-full text-3xl " htmlFor="Date">Name  </label>
                                                                                <input maxLength='15' onChange={(e) => setNameCustomer(e.target.value)} value={nameCustomer} type="text" placeholder='Name' name="" id="Date" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-2xl  " />

                                                                      </div>
                                                            </div>
                                                            <button onClick={handleBooking} className="OutlineBtn flex items-center justify-center p-4 rounded-lg text-3xl relative  font-medium py-6 bg-mainColor text-white border-2 w-full">Booking</button>

                                                  </div>
                                        }
                              </>


                    </div>
          )
}
const useFormInput = initialValue => {
          const [value, setValue] = useState(initialValue);

          const handleChange = event => {
                    setValue(event.target.value);
          }
          return {
                    value,
                    onChange: handleChange
          }
}
export default Phone