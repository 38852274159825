import axios from 'axios'
import React, { useState } from 'react'
import { configs, url } from '../../Commens/Commens';
import Error from '../UI/Alert/Error';

const EditProfile = (props) => {
    const [Username, setUsername] = useState('');
          const [birthdate, setbirthdate] = useState('');
          const [gender, setgender] = useState('');
          const [email, setemail] = useState('');
          const [success, setSuccess] = useState(false)
          const [errorMessge, setErrorMessage] = useState()
          const [error, setError] = useState(false)

    const updateAccount = () => {
        axios.put(url + `account?name=${Username}&birthdate=${birthdate}&gender=${gender}&email=${email}`, null, configs).then(response => {
            console.log(response)
            setUsername('')
            setbirthdate('')
            setgender('')
            setemail('')
            props.updateProfile(Username)
                  setSuccess(true)
                  setTimeout(() => {
                            setSuccess(false)
                            localStorage.setItem('user', Username);

                  }, 2000);
        }).catch(error => { setErrorMessage(error.message);
            console.log(error)
            setError(true)
            setTimeout(() => {
                      setError(false) 

            }, 2000);
         })
}

  return (
   <>
     {
                error && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
                        <Error>
                            {errorMessge}
                        </Error>
                    </div>
            }
    <div className="w-full md:w-1/3">
        <div className=" flex flex-wrap px-4 xl:px-8">
                <h2 className='w-full text-mainColor my-8 text-2xl font-bold'>profile</h2>
                <div className="form-group w-full">
                    <input className='text-2xl p-4 border-2 border-mainColor w-full  m-2 rounded-lg' type="text" onChange={(e) => setUsername(e.target.value)} placeholder='name' value={Username} />
                </div>
                <div className="form-group w-full"> 
                    <input className='text-2xl p-4 border-2 border-mainColor w-full  m-2 rounded-lg' type="date" onChange={(e) => setbirthdate(e.target.value)} placeholder='birthdate' value={birthdate} />
                </div>
                <div className="form-group w-full"> 
                    <input className='text-2xl p-4 border-2 border-mainColor w-full  m-2 rounded-lg' type="email" onChange={(e) => setemail(e.target.value)} placeholder='email' value={email} />
                </div>
                <div className="flex w-full">
                    <label className="frequancyItem flex items-center mt-8 w-full radioBox ">
                        <div className="w-12">
                            <input type="radio" onChange={(e) => setgender(e.target.value)} placeholder='gender' value='male' name='gender' />Male
                            <span className="checkmark"></span>
                        </div>
                    </label> 
                    <label className="frequancyItem flex items-center mt-8 w-full radioBox ">
                        <div className="w-12">
                            <input type="radio" onChange={(e) => setgender(e.target.value)} placeholder='gender' value='female' name='gender' />Female
                            <span className="checkmark"></span>
                        </div>
                    </label> 
                </div>
                
                {/* <input className='text-xl p-4 border-2 border-mainColor w-full  m-2 rounded-lg' readOnly type="tel" onChange={(e) => setphone(e.target.value)} placeholder='phone' value={phone} /> */}
                <button onClick={updateAccount} className='text-2xl p-4 border-2 bg-mainColor w-full  m-2 rounded-lg text-white'> Update </button>
        
        </div>
    </div>
    <div className="w-full md:w-1/3">
        <div className="row flex flex-wrap px-4 xl:px-8">
                <h2 className='w-full text-mainColor my-8 text-2xl font-bold'>My Data</h2>
                <div className="form-group w-full text-2xl my-4 px-4">
                        <h3> <span className='text-mainColor font-bold'>You Name :</span> {Username}</h3>
                </div>
                <div className="form-group w-full text-2xl my-4 px-4"> 
                        <h3> <span className='text-mainColor font-bold'> You birthdate :</span>  {birthdate}</h3>
                </div>
                <div className="form-group w-full text-2xl my-4 px-4"> 
                        <h3> <span className='text-mainColor font-bold'>You gender :</span>  {gender}</h3>
                </div>
                <div className="flex w-full text-2xl my-4 px-4">
                        <h3> <span className='text-mainColor font-bold'>You email :</span>  {email}</h3>
                </div>
                
        
        </div>
    </div>
    </>
  )
}

export default EditProfile