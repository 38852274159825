export const url = 'https://admin.dangiclean.com/public/api/v1/'


//  the configs 
export const configs = {
          headers: {
                    'Accept': 'application/json',
                    'Accept-Language': localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
}

export const configsAuth = {
          headers: {
                    'Accept': 'application/json',
                    'accept-language:': localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'en',
                    "Authorization": `Bearer ${localStorage.getItem('token')}`
          }
}

export const getBookingObj = () => {
          if (localStorage.bookingObj) {
                    return JSON.parse(localStorage.getItem('bookingObj'));
          } else {
                    const obj = {}
                    obj.location_type = 'full'
                    localStorage.setItem('bookingObj', JSON.stringify(obj));
                    return obj
          }
}

window.onunload = function () {
          localStorage.removeItem('bookingObj');
}


export const setBookingObj = (obj) => {
          localStorage.setItem('bookingObj', JSON.stringify(obj));
}


export const updateCostCard = (res) => {
          var errors = document.getElementById("error");
          errors.style.display = 'none';
          var price = document.getElementById("cartPrice");
          price.textContent = res.price;

          var currency = document.getElementById("cartCurrency");
          currency.textContent = res.currency;

          var vat = document.getElementById("cartVat");
          vat.textContent = res.vat;

          var total = document.getElementById("cartTotal");
          total.textContent = res.total;

          var details = document.getElementById("cartDetails");
          const htmlString = res.details
          details.innerHTML = htmlString

}

export const getErrorInCard = (res) => {
          // const errorMessage = res.response.data.message
          // const errorType = res.response.status
          const errorArray = res.response.data.error
          var errors = document.getElementById("error");

          if (errorArray.length > 0) {
                    errors.style.display = 'flex';
                    for (var i = 0; i < errorArray.length; i++) {
                              console.log(errorArray)
                              errors.textContent = (errorArray);
                    }
          }


}

export const showLoading = (status) => {
          var all = document.getElementsByClassName('loading');
          if (status) {
                    // console.log("showing loading")
                    for (var i = 0; i < all.length; i++) {
                              all[i].style.display = 'block';
                    }
          } else {
                    for (i = 0; i < all.length; i++) {
                              all[i].style.display = 'none';
                    }
          }
}
