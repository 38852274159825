
import { useEffect } from 'react'
import { useTranslation } from "react-i18next"
import i18next from 'i18next'
import uae from '../../img/uae.png'
import en from '../../img/en.png'
import ng from '../../img/ng.png'

const languages = [
          {
                    img: en,
                    code: 'en',
                    name: 'EN',
                    country_code: 'gb',
          },
          {
                    img: ng,
                    code: 'ha',
                    name: 'HA',
                    dir: 'ltr',
                    country_code: 'ha',
                    lang: 'ha',
          },
          {
                    img: uae,
                    code: 'ar',
                    name: 'AR',
                    dir: 'rtl',
                    country_code: 'sa',
                    lang: 'ar',
          },
]

const Lang = () => {
          const { t } = useTranslation()
          const currentLanguageCode = localStorage.getItem('i18nextLng') || 'ar'
          const currentLanguage = languages.find(l => l.code === currentLanguageCode)
          useEffect(() => {
                    document.documentElement.dir = currentLanguage.dir || 'ltr'
                    document.documentElement.lang = i18next.language;
                    // console.log(document.documentElement.lang)
                    // console.log(i18next.language)
                    // document.documentElement.dir = currentLanguage.dir || 'ltr'

                    // document.title = t('app_title')
          }, [currentLanguage, t])


          return (
                    <>
                              {
                                        languages.map(({ code, name, country_code, img }) => (
                                                  <div key={country_code}>
                                                            {/* onClick={() => i18next.changeLanguage(code)}  */}
                                                            {/* onClick={() => { window.location.reload(true);  setTimeout(i18next.changeLanguage(code), 1000);} }  */}
                                                            <button className='langButton flex flex-col items-center text-3xl mx-4 text-white font-bold w-12' onClick={() => {setTimeout( window.location.reload(true), 2000);   i18next.changeLanguage(code)} }   style={{ display: code === currentLanguageCode ? 'none' : 'flex' }} disabled={code === currentLanguageCode}>
                                                                      <img src={img} alt="" className='w-12 rounded-lg' />
                                                                      {/* <p className='text-gray-700 my-0'>{name}</p> */}
                                                            </button>
                                                  </div>
                                        ))

                                        // <ul className="mx-4" aria-labelledby="dropdownMenuButton1">
                                        //           <li className=' font-bold  text-white  text-2xl'>
                                        //                     <span className="dropdown-item-text">{t('language')}</span>
                                        //           </li>
                                        //           <div className="absolute  overflow-hidden    top-full bg-white px-6 -z-50">
                                        //                     {languages.map(({ code, name, country_code, img }) => (
                                        //                               <li key={country_code} >
                                        //                                         <button onClick={() => { i18next.changeLanguage(code) }} className="font-bold py-1  text-subColor  text-2xl flex items-start mb-4">
                                        //                                                   <img src={img} alt="" className='w-8 ' />
                                        //                                                   <span className={`flag-icon flag-icon-${country_code} mx-2`} style={{ opacity: currentLanguageCode === code ? 0.5 : 1, }} > {name}</span>
                                        //                                         </button>
                                        //                               </li>
                                        //                     ))}
                                        //           </div>

                                        // </ul>
                              }
                    </>
          )
}

export default Lang