import {configs, url} from '../Commens/Commens';
import React, {Fragment, useEffect, useState} from 'react'
import Layout from '../Component/Layout'
import {RiArrowRightSLine} from 'react-icons/ri'

import axios from 'axios';
import Error from '../Component/UI/Alert/Error';
import Success from '../Component/UI/Alert/Success';
import EditProfile from '../Component/Profile/EditProfile';
import AddAddress from '../Component/Profile/AddAddress';
import SiteSettings from '../Component/Profile/SiteSettings';

const Profile = () => {
    // const [modal, setModal] = useState(false);
    const [SiteSettingTap, setSiteSettingTap] = useState(false);
    const [addressTap, setAddressTap] = useState(false);
    const [ProfileTap, setProfileTap] = useState(true);
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessge, setErrorMessage] = useState()

   
 
 
    const handleUpdateProfile = (Username) => {
        setSuccess(true)
        localStorage.setItem('user', Username);

        setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }

    const handleAddAddress = () => {
        setSuccess(true)
        setTimeout(() => {
            setSuccess(false)
        }, 2000);
    }

    var user = localStorage.getItem('user');

    const Logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location.href ='/'
    
      }

    return (
        <Layout>
            {
                error && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
                        <Error>
                            {errorMessge}
                        </Error>
                    </div>
            }
            {
                success && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-greenLight z-50 flex items-center justify-center">
                        <Success></Success>
                    </div>
            }

            <Fragment>

                <div id="Address" className='min-h-screen '>
                    <div className="container mx-auto">
                        <div className="row flex flex-wrap item-start justify-center pt-8">
                            <div className="w-full md:w-1/3 flex items-start justify-start px-4 lg:justify-center">
                                <div className='my-8'>
                                    <h3 className='font-bold text-2xl'>My Account</h3>
                                    {localStorage.token && <h4 className='text-mainColor font-bold text-2xl my-4'>{user}</h4>}
                                    <h2 className='underline text-mainColor my-8 text-2xl font-bold'>Account Settings</h2>
                                    <ul>
                                        <li
                                            onClick={() => {
                                                setProfileTap(true);
                                                setSiteSettingTap(false);
                                                setAddressTap(false)
                                            }}
                                            className={`cursor-pointer my-8 text-2xl font-bold flex items-center justify-between ${ProfileTap && 'text-green'}`}>Edit Personal Details
                                            <RiArrowRightSLine className="text-2xl"/></li>
                                        <li
                                            onClick={() => {
                                                setProfileTap(false);
                                                setSiteSettingTap(false);
                                                setAddressTap(true)
                                            }}
                                            className={`cursor-pointer my-8 text-2xl font-bold flex items-center justify-between ${addressTap && 'text-green'}`}>Manage Addresses
                                            <RiArrowRightSLine className="text-2xl"/></li>
                                    </ul>
                                     <h2 onClick={Logout} className='cursor-pointer underline text-red my-0 lg:my-8 text-2xl font-bold'>Logout</h2>
                                 {/*   <ul>
                                        <li
                                            onClick={() => {
                                                setProfileTap(false);
                                                setSiteSettingTap(true);
                                                setAddressTap(false)
                                            }}
                                            className={`cursor-pointer my-8 text-2xl font-bold flex items-center justify-between ${SiteSettingTap && 'text-green'}`}>
                                            Language / Country
                                            <FaLanguage/>
                                        </li>
                                    </ul> */}
                                </div>
                            </div>
                            {addressTap && <AddAddress onAddAddress={handleAddAddress}/>}

                            {ProfileTap && <EditProfile updateProfile={handleUpdateProfile}/>}

                            {SiteSettingTap && <SiteSettings/>}
                        </div>
                    </div>
                </div>
            </Fragment>
        </Layout>
    )
}

export default Profile