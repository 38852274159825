import React from 'react'
import { Link } from 'react-router-dom'
import Layout from '../Component/Layout'
import logo from '../img/h_logo.png'
const ThankYou = () => {
  return (
    <Layout>
    <div className='min-h-screen flex items-center justify-center bg-mainColor'>
              <div className="container mx-auto">
                        <div className="row flex items-center justify-center flex-col h-full">
                                  <h3 className='text-6xl md:text-9xl mb-16 font-bold text-white'>Thank you</h3>
                                  <h3 className='text-3xl md:text-5xl mb-16 font-bold text-white'>You will receive an email confirmation</h3>
                                  <img src={logo} alt="" className=' mb-16' />
                                  <Link to='/' className='font-bold text-3xl my-8 border-4 px-6 py-4 rounded-lg text-white text-center hover:bg-white hover:text-mainColor hover:border-white' > Back To Home Page </Link>
                        </div>
              </div>
    </div>
</Layout>
  )
}

export default ThankYou