import Layout from '../Component/Layout'
import Button from '../Component/UI/Button/Button'
import { RiArrowDropLeftLine,RiMapPinLine, RiArrowDropRightLine } from 'react-icons/ri'
import { BsArrowRepeat , BsCalendar2Plus, BsCashCoin} from 'react-icons/bs'
import { MdCleaningServices } from 'react-icons/md'

import Address from '../Component/Booking/Address'
import Details from '../Component/Booking/Details'
import DateTime from '../Component/Booking/DateTime'
import Frequency from '../Component/Booking/Frequency'
import { useState } from 'react'
import Phone from '../Component/Auth/Phone'
import Error from '../Component/UI/Alert/Error'

const startComponent = () => {
  return <Frequency />
}

const firstComponent = () => {
  return <Address />
}
const secondComponent = () => {
  return <Details />
}
const thirdComponent = () => {
  return <DateTime />
}
const finalComponent = () => {
  return <Phone />
}


const ServiceDetails = () => {
  const [error, setError] = useState(false)
  const [errorMessge, setErrorMessage] = useState([])
  const [steps, setSteps] = useState([
    {
      key: 'startStep', label:
      <BsArrowRepeat className='text-5xl text-white' />
      , isDone: true, component: startComponent
    },
    {
      key: 'firstStep', label:
        <RiMapPinLine  className='text-5xl text-white' />
      , isDone: true, component: firstComponent
    },
    {
      key: 'secondStep', label:
        <MdCleaningServices className='text-5xl text-white' />
      , isDone: false, component: secondComponent
    },
    {
      key: 'thirdStep', label:
        <BsCalendar2Plus  className='text-5xl text-white'  />
      , isDone: false, component: thirdComponent
    },
    {
      key: 'finalStep', label:
        <BsCashCoin  className='text-5xl text-white'  />
      , isDone: false, component: finalComponent
    },
  ]);

  const [activeStep, setActiveStep] = useState(steps[0]);

  const handleNext = () => { 
    if (steps[steps.length - 5].key === activeStep.key) {
      if (!localStorage.bookingObj) {
        setErrorMessage('Please choose the answer !!')
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      } else {
        const index = steps.findIndex(x => x.key === activeStep.key);
        setSteps(prevStep => prevStep.map(x => {
          if (x.key === activeStep.key) x.isDone = true;
          return x;
        }))
        setActiveStep(steps[index + 1]);
      }
      return
    }

    if (steps[steps.length - 4].key === activeStep.key) {
      let newObject = window.localStorage.getItem("bookingObj");
      const locationVal = JSON.parse(newObject).location_value
      if (!locationVal) {
        setErrorMessage('Please choose select your location !!')
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
        if (locationVal.address2 == '') {
          setErrorMessage('Please choose select your location !!')
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 2000);
        }  
      } else {
        const index = steps.findIndex(x => x.key === activeStep.key);
        setSteps(prevStep => prevStep.map(x => {
          if (x.key === activeStep.key) x.isDone = true;
          return x;
        }))
        setActiveStep(steps[index + 1]);
      }

      return
    }

    if (steps[steps.length - 3].key === activeStep.key) {
      let newObject = window.localStorage.getItem("bookingObj");
      const servicesVal = JSON.parse(newObject).services
      if (servicesVal) {
        if (servicesVal.length > 0) {
          const index = steps.findIndex(x => x.key === activeStep.key);
          setSteps(prevStep => prevStep.map(x => {
            if (x.key === activeStep.key) x.isDone = true;
            return x;
          }))
          setActiveStep(steps[index + 1]);
        } else {
          setErrorMessage('Please choose select a service !!')
          setError(true)
          setTimeout(() => {
            setError(false)
          }, 2000);
        }
      } else {
        setErrorMessage('Please choose select a service !!')
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      }
      return
    }

    if (steps[steps.length - 2].key === activeStep.key) {
      let newObject = window.localStorage.getItem("bookingObj");
      const dateVal = JSON.parse(newObject).date
      if (!dateVal) {
        setErrorMessage('Please select a Date and Time !!')
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      }
      if (dateVal.length < 19) {
        setErrorMessage('Please select a Date and Time !!')
        setError(true)
        setTimeout(() => {
          setError(false)
        }, 2000);
      } else {
        const index = steps.findIndex(x => x.key === activeStep.key);
        setSteps(prevStep => prevStep.map(x => {
          if (x.key === activeStep.key) x.isDone = true;
          return x;
        }))
        setActiveStep(steps[index + 1]);
      }

      return
    }

    if (steps[steps.length - 1].key === activeStep.key) {
      alert('You have completed all steps please confirm your booking.');
      return;
    }

  }

  const handleBack = () => {
    const index = steps.findIndex(x => x.key === activeStep.key);
    if (index === 0) return;

    setSteps(prevStep => prevStep.map(x => {
      if (x.key === activeStep.key) x.isDone = false;
      return x;
    }))
    setActiveStep(steps[index - 1]);
  }

  return (
    <Layout>
      {error &&
        <div className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
          <Error> {errorMessge} </Error>
        </div>
      }
      <div id="ServiceDetails" className='min-h-screen mt-12 lg:mt-48'>
        <div className="container mx-auto">
          <div className="row flex items-start justify-between flex-wrap" >

            <div className="w-full">
              <div className="steps">
                <ul className="nav flex items-center justify-between mb-8 text-2xl relative overflow-hidden">
                  {steps.map((step, i) => {
                    return <li key={i} className={`w-28 h-28 flex items-center justify-center text-center p-4 leading-10 my-2 lg:mx-4 fill-subColor ${activeStep.key === step.key ? 'active bg-mainColor text-subColor rounded-full  fill-white ' : 'bg-gray rounded-full'} ${step.isDone ? 'done bg-mainColor' : ''}`}>
                      <div className='font-bold '><span className='font-normal flex justify-center'>{step.label}</span></div>
                    </li>
                  })}
                </ul>
              </div>
            </div>
            <div className="w-full lg:w-8/12 px-4 lg:pr-8 mt-6" >
              <div className="box">

                <div className="step-component">
                  {activeStep.component()}
                </div>

              </div>
            </div>
            <div className="w-full lg:w-4/12">
              <div className="Cart p-4 px-8 mt-12 border-2 border-mainColor rounded-xl mx-4">
                <ul className='text-2xl relative overflow-hidden'>
                  <div className="loading hidden absolute left-0 top-0 w-full h-full z-50 opacity-90 items-center justify-center bg-white ">
                    <div className="Loading h-full w-full  mx-auto flex items-center justify-center">
                      <div className="loadingio-spinner-spinner-b5p7g8eokig"><div className="ldio-dlp895cf7od">
                        <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
                      </div></div>
                    </div>
                  </div>
                  <ul className='font-bold my-4 w-full h-full absolute text-red leading-10 text-center bg-white  hidden items-center justify-center' id='error'>
                  </ul>
                  <li className='font-bold my-4'>Cart Details</li>
                  <li id='cartDetails' className='text-2xl my-4 leading-10'>ــــــــــــــــ </li>
                  <hr className='my-8 border border-mainColor' />
                  <li className='font-bold mb-4'>PRICE DETAILS</li>
                  <li className='text-2xl my-4 flex items-center justify-between'><span>Price</span><span>  <b id='cartPrice'>00.00</b>  <b id="cartCurrency">AED</b> </span> </li>
                  <li className='text-2xl my-4 flex items-center justify-between'><span>VAT</span><span><b id='cartVat'>00.00</b> <b id="cartCurrency">AED</b></span> </li>
                  <li className='font-bold text-2xl my-4 flex items-center justify-between'><span>Total</span><span><b id='cartTotal'>00.00</b> <b id="cartCurrency">AED</b></span> </li>
                </ul>
              </div>


              <div className="flex justify-end items-center px-4 mt-12">
                <button  className="p-4 py-6 bg-mainColor text-2xl opacity-40 hover:opacity-90  text-white flex items-center mt-8 justify-center text-center w-1/2 mx-2" value="Back" onClick={handleBack} disabled={steps[0].key === activeStep.key}>
                    Back <RiArrowDropLeftLine className='text-3xl' />
                </button>
                <button  className="p-4 py-6 bg-mainColor text-2xl text-white flex items-center mt-8 justify-center text-center w-1/2 mx-2" value={steps[steps.length - 1].key !== activeStep.key ? 'Next' : 'Submit'} onClick={handleNext}>
                    Next <RiArrowDropRightLine className='' />
                </button>

              </div>


            </div>
          </div>
        </div >
      </div >
    </Layout>
  )
}

export default ServiceDetails

