import wave from '../../../img/wavee.png'
import man from '../../../img/avatarBanner.png'
import Button from '../../UI/Button/Button'
import { GoCalendar } from 'react-icons/go'
import './index.scss'
const index = () => {
  return (
    <div id="Banner" className='bg-mainColor relative min-h-screen overflow-hidden'>
      <img src={wave} alt="" className='absolute -bottom-6 lg:-bottom-16 left-0 w-screen z-20' />
      <div className="container mx-auto">
        <div className="row flex items-center justify-center min-h-screen flex-wrap text-center">
          <div className="w-full md:w-1/2 relative z-30 pt-36 lg:p-0 flex items-center text-center justify-center lg:justify-start lg:text-left flex-wrap">
            <h3 className='subColor text-4xl mb-4 font-medium w-full text-white '>Get Amazing Cleaning Services</h3>
            <div className='text-white text-6xl sm:text-5xl lg:text-7xl w-full font-bold flex flex-wrap mb-10 lg:mb-0 h-12 lg:h-28 items-center justify-center lg:justify-start'> Complete <b className='font-face text-yellow mx-4'> Cleaning </b> </div>
            <div className='text-white text-6xl sm:text-5xl lg:text-7xl w-full font-bold flex flex-wrap mb-10 lg:mb-0 h-12 lg:h-28 items-center justify-center lg:justify-start'> And  <b className='font-face text-yellow mx-4'> Sautation </b>  For  </div>
            <div className='text-white text-6xl sm:text-5xl lg:text-7xl w-full font-bold flex flex-wrap mb-10 lg:mb-0 h-12 lg:h-28 items-center justify-center lg:justify-start'> Your Home  </div>
            <div className="text-center flex justify-center lg:justify-start mt-4 items-center mb-8 w-full">
              <a href='#Book' className="mainBtn mt-4">
                <GoCalendar className='mx-4' /> Book a Schedule
              </a>
            </div>
          </div>
          <div className="w-full md:w-1/2 flex items-center justify-center ">
            <img src={man} alt="" className='relative mx-auto ' />
          </div>
          
        </div>
      </div>
    </div >
  )
}

export default index