import React, { useState } from 'react'
import Layout from '../Component/Layout'
import wave from '../img/wavee.jpg'
import contact from '../img/contact.png'
import { AiOutlineSend, AiOutlineWhatsApp, AiOutlineInstagram, AiOutlineMail } from 'react-icons/ai'
import { FaTiktok } from 'react-icons/fa'
import { FiFacebook } from 'react-icons/fi'
import { BiPhoneCall } from 'react-icons/bi'
import axios from 'axios'
import { configs, url } from '../Commens/Commens'

const Contact = () => {
  const [fullName, setFullName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [message, setMessage] = useState('')
  const handleSubmit = () => {
    console.log(fullName, mobileNumber, message)
    if (fullName.trim('').length < 6 || mobileNumber.trim('').length < 6 || !message) {
      console.log('check your data')
      return
    }
    let data = new FormData();
    data.append('name', fullName);
    data.append('phone', mobileNumber);
    data.append('message', message); 
    axios.post(url + `contact`, data, configs).then(response => {
      console.log(response.data)
      setFullName('')
      setMobileNumber('')
      setMessage('') 
    }).catch(error => {
      console.log(error.message)
    })
  }

  return (
    <Layout>
      <div id="Contact" className='bg-mainColor relative  overflow-hidden py-12'>
        <img src={wave} alt="" className='absolute -bottom-16 left-0 w-screen hidden lg:block' />
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">

            <div className="w-full md:w-1/2 flex items-center justify-center">
              <img src={contact} alt="" className='relative w-full h-auto lg:mb-64' />
            </div>

            <div className="w-full md:w-1/2 relative z-30">
              <div className='Form mt-24 py-16 px-4 lg:px-8 rounded-xl bg-white'>
                <div className="row flex items-center justify-center flex-wrap text-right ">
                  <div className="w-full md:w-1/2 text-left p-4">
                    <label className="text-gray text-3xl " htmlFor="FullName">Full Name</label>
                    <input onChange={(e) => setFullName(e.target.value)} value={fullName} placeholder='Full Name' autoComplete="true" id="FullName" type="text" className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md " />
                  </div>
                  <div className="w-full md:w-1/2 text-left p-4">
                    <label className="text-gray text-3xl " htmlFor="mobile"> Mobile Number</label>
                    <input onChange={(e) => setMobileNumber(e.target.value)} value={mobileNumber} placeholder='Mobile Number' autoComplete="true" id="mobile" type="tel" className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md " />
                  </div>
                  <div className="w-full text-left p-4">
                    <label className="text-gray text-3xl " htmlFor="mobile"> Message</label>
                    <textarea onChange={(e) => setMessage(e.target.value)} value={message} name="" id="" cols="30" rows="5" placeholder='Write you Message' className="block w-full px-4 py-4 mt-2 text-gray-700  border border-mainColor text-3xl rounded-md " ></textarea>
                  </div>
                 
                  <button onClick={handleSubmit}  className="mainBtn blueBtn mt-8">
                    <AiOutlineSend className='mx-4' />
                    Send
                  </button>
                </div>
              </div>
              <ul className='flex flex-wrap items-center justify-between w-full font-bold lg:mb-64'>
                <li className='mt-12 text-3xl text-white w-full '>
                  <a href='tel:0500000000000' className='flex justify-center lg:justify-start items-center  lg:items-start '><BiPhoneCall className='mr-4' /> +971 50 000 0000</a>
                </li>
                <li className='mt-12 text-3xl text-white w-full '>
                  <a href='email:0500000000000' className='flex justify-center lg:justify-start items-center  lg:items-start '><AiOutlineMail className='mr-4' />  info@dangiclean.com</a>
                </li>
                <ul className='flex items-center  lg:justify-start justify-center w-full mt-12'>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="http://www.facebook.com"><AiOutlineInstagram /></a> </li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="http://www.facebook.com"><AiOutlineWhatsApp /></a></li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="http://www.facebook.com"><FaTiktok /></a></li>
                  <li className='pr-4 text-5xl text-white'>
                    <a href="http://www.facebook.com"><FiFacebook /></a></li>
                </ul>
              </ul>


            </div>

          </div>
        </div>
      </div >
      <div className="map">
        <div className="row flex item-center justify-center">
          <div className="container mx-auto">
            <div className="w-full my-24">
              <iframe className='w-full rounded-3xl' style={{ height: '50rem' }} title='map' id="gmap_canvas" src="https://maps.google.com/maps?q=brain%20click&t=&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact