import React from 'react'
import Layout from '../Component/Layout'
import WhyDangiCleaners from '../Component/WhyDangiCleaners/WhyDangiCleaners'
import logo from '../img/h_logo.png'
import wave from '../img/wavee.jpg'
import about from '../img/about.png'

const About = () => {
  return (
    <Layout>
      <div id="Banner" className='bg-mainColor relative min-h-screen overflow-hidden '>
        <img src={wave} alt="" className='absolute -bottom-16 left-0 w-screen' />
        <div className="container mx-auto px-4 lg:px-0">
          <div className="row flex items-center justify-center min-h-screen flex-wrap">
            <div className="w-full md:w-1/2 relative z-30">
              <img src={logo} alt="" />
              <p className='text-2xl leading-loose mb-8 mt-4 text-white'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
            <div className="w-full md:w-1/2 flex items-center justify-center h-72">
              <img src={about} alt="" className='relative w-full' />
            </div>
          </div>
        </div>
      </div >
      <WhyDangiCleaners />

    </Layout>
  )
}

export default About