import React from 'react'
import Map from './Map'
// import getBookingObj from '../../Commens/Commens'

const Address = () => {

  return (
    <> 
      <Map />
    </>
  )
}

export default Address