import { useEffect, useState } from "react";
import Button from "../../UI/Button/Button";
import { FaHandsWash } from "react-icons/fa";
import { GiMoneyStack } from "react-icons/gi";
import Avatar from "../../../img/avatar1.png";
import Avatar2 from "../../../img/avatar2.png";
import Avatar3 from "../../../img/avatar3.png";
import Avatar4 from "../../../img/avatar4.png";
import "./index.scss";
import { configs, url } from "../../../Commens/Commens";
import axios from "axios";
import Service from "../../Service/Service";
import { Link } from "react-router-dom";

const BestServices = () => {
  const [isNotFound, setIsNotFound] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [service, setServices] = useState({});

  useEffect(() => {
    axios
      .get(url + "categories?parent=-1", configs)
      .then((response) => {
        if (response.status === 404) {
          setIsNotFound(true);
          return;
        }
        if (response.status === 400) {
          setIsNotValid(true);
          return;
        }
        const data = response.data.data;
        setServices(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  if (isNotFound) {
    return <p>NotFound</p>;
  }
  if (isNotValid) {
    return <p className="error">Sorry! The reservation ID is invalid.</p>;
  }
  if (isLoading) {
    return <p className="error">Load.</p>;
  }

  return (
    <>
      <div id="BestServices" className="mt-24">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">
            <div className="w-full md:w-6/12 xl:w-1/4 lg:pr-12">
              <h3 className="text-4xl mb-6 font-medium text-subColor text-center md:text-left">
                Our Best Services
              </h3>
              <p className="text-2xl leading-loose mb-8 text-center md:text-left">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi
              </p>
              <div className="flex justify-center md:justify-start items-center mb-8 ">
                <Link to='/Category/-1' className="mainBtn blueBtn mt-4">
                  <FaHandsWash className="mx-4" />
                  Explore Our Services
                </Link>
              </div>
            </div>
            <div className="w-full md:w-6/12 xl:w-1/8">
              <div className="row flex justify-center items-center flex-wrap ">
                {service.map((item, index) => (
                  <Service
                    id={item.id}
                    key={index}
                    image={item.icon}
                    name={item.name}
                    description={item.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="LittleTip" className="mt-24 lg:mt-72">
        <div className="container mx-auto">
          <div className="row flex items-center justify-center flex-wrap">
            <div className="w-full md:w-8/12  relative">
              <div className="avatar relative h-auto lg:h-96 py-32 lg:py-16 lg:p-16">
                <img src={Avatar} alt="" className="absolute -top-24 right-0 lg:right-1/4  w-48 lg:w-72 h-auto" />
                <img src={Avatar2} alt="" className="absolute -top-24 left-0 w-48 lg:w-72 h-auto" />
                <div className="lg:hidden text-center">
                  <h3 className="text-4xl mb-6 font-medium text-subColor"> A little tip can go a long way
                  </h3>
                  <p className="text-2xl leading-loose mb-8 "> Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
                  </p>
                  <div className="flex justify-center items-center mb-8">
                    <Link to="/Tip" className="mainBtn mt-4">
                      <GiMoneyStack className=" text-3xl mx-4" />
                      Add a Tip
                    </Link>
                  </div>
                </div>
                <img src={Avatar3} alt="" className="absolute -bottom-24 right-0 lg:right-16 w-48 lg:w-72 h-auto" />
                <img src={Avatar4} alt="" className="absolute -bottom-24 left-0 lg:left-1/4 w-48 lg:w-72 h-auto" />
              </div>
            </div>
            <div className="w-full md:w-4/12 lg:pl-12 hidden lg:block">
              <h3 className="text-4xl mb-6 font-medium text-subColor">
                A little tip can go a long way
              </h3>
              <p className="text-2xl leading-loose mb-8 ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi
              </p>
              <div className="flex justify-start items-center mb-8">
                <Link to="/Tip" className="mainBtn blueBtn mt-4">
                  <GiMoneyStack className=" text-3xl mx-4" />
                  Add a Tip
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestServices;
