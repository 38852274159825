import { Link, useParams } from 'react-router-dom'
import { configs, url } from '../../Commens/Commens'
import axios from 'axios'
import { useEffect, useState } from 'react';
import Loader from '../UI/Loader/Loader';


const OurServices = () => {
          const { id } = useParams();
          const [isNotFound, setIsNotFound] = useState(false);
          const [isNotValid, setIsNotValid] = useState(false);
          const [isLoading, setIsLoading] = useState(true);
          const [service, setServices] = useState({});

          useEffect(() => {
                    axios.get(url + `categories?filter=home`, configs).then(response => {
                              if (response.status === 404) { setIsNotFound(true); return; }
                              if (response.status === 400) { setIsNotValid(true); return; }
                              const data = (response.data.data)
                              setServices(data.slice(0, 16));
                              setIsLoading(false);
                    }).catch(error => { console.log(error) })
          }, [id]);
          if (isNotFound) { return (<p>NotFound</p>) }
          if (isNotValid) { return (<p className="error">Sorry! The reservation ID is invalid.</p>) }
          if (isLoading) { return (<div className="Loading mt-72"> <Loader /> </div>) }

          return (
                    <div className="OurServices mt-72">
                              <div className="container mx-auto">
                                        <div className="row flex items-center justify-center flex-wrap" >
                                            <div className="address text-center h-72 relative overflow-hidden lg:hidden">
                                                    <h3 className='text-4xl mb-6 font-medium text-subColor'>Our Services</h3>
                                                    <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                            </div>
                                                  {
                                                            service.map((item, index) => (
                                                                      <div className={`relative p-2 h-72 w-1/2 md:w-1/6 flex ${index ===2 && 'hidden lg:flex md:w-3/6'}`} key={index}>
                                                                                {index === 2 &&
                                                                                          <div className="address text-center h-72 relative overflow-hidden lg:p-8">
                                                                                                    <h3 className='text-4xl mb-6 font-medium text-subColor'>Our Services</h3>
                                                                                                    <p className='text-2xl l text-gray leading-loose'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi</p>
                                                                                          </div>
                                                                                }
                                                                                <Link to={`/ServiceDetails/${item.id}`} className="flex items-center justify-center flex-col border-2 border-mainColor w-full h-full  rounded-xl p-4 text-center">
                                                                                          <img src={item.icon} alt="" className='h-28 mb-8 mx-auto' />
                                                                                          <h3 className='text-3xl  font-medium text-subColor'>{item.name}</h3>
                                                                                </Link>

                                                                      </div>
                                                            ))
                                                  }
                                        </div>
                              </div >
                    </div >
          )
}

export default OurServices