import React, {Fragment, useEffect, useState} from 'react'
import {MdDeleteOutline} from 'react-icons/md'
// import { AiOutlineEdit } from 'react-icons/ai'

import axios from 'axios';
import {LoadScript, GoogleMap, StandaloneSearchBox} from '@react-google-maps/api';
import pin from '../../img/pin.svg'
import {configs, showLoading, url} from '../../Commens/Commens';
import Error from '../UI/Alert/Error';

const AddAddress = (props) => {
    // const [modal, setModal] = useState(false);
    const [allAddresses, setAllAddresses] = useState([]);
    const [pickLocation, setPickLocation] = useState('');
    const [address, setAddress] = useState('');
    const [mapref, setMapRef] = useState(null);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();
    const [defaultLocation, setDefaultLocation] = useState(
        {lat: 24.2048, lng: 54.2708}
    );
    const [error, setError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [errorMessge, setErrorMessage] = useState()

    useEffect(() => {
        if (!localStorage.token) {
            return
        }
        axios
            .get(url + `addresses`, configs)
            .then(response => {
                setAllAddresses(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })

        }, [success])

    useEffect(() => {
        if (!localStorage.token) {
            return
        }
        axios
            .get(url + `profile`, configs)
            .then(response => {
                console.log(response.data.data)
            })
            .catch(error => {
                console.log(error)
            })

        }, [])

    const handleDelete = (id) => {
        axios
            .delete(url + `addresses/${id}`, configs)
            .then(response => {
                setSuccess(true)
                setTimeout(() => {
                    setSuccess(false)
                }, 2000);
            })
            .catch(error => {
                console.log(error)
            })
        }

    const handleOnLoad = map => {
        setMapRef(map);
    };

    const displayLocation = (latitude, longitude) => {
        var request = new XMLHttpRequest();
        var method = 'GET';
        var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' +
                latitude + ',' + longitude + '&sensor=true&key=AIzaSyAV8VEG1RLclapyZ92xOujbsX1l' +
                'RnIksdc';
        var async = true;

        request.open(method, url, async);
        request.onreadystatechange = function () {
            if (request.readyState == 4 && request.status == 200) {
                var data = JSON.parse(request.responseText);
                var address = data.results[0];
                setPickLocation(address.formatted_address)
                //    $('#map-location').html(address.formatted_address);
            }
        };
        request.send();
    };

    const handleCenterChanged = (e) => {

        if (mapref) {
            const newCenter = mapref.getCenter();
            setLat(newCenter.lat());
            setLng(newCenter.lng());
            displayLocation(newCenter.lat(), newCenter.lng())
            document
                .getElementById("searchMap")
                .value = '';
        }
    };

    const updateFunObj = () => {

        if (lng === undefined || lat === undefined || address.length < 5) {
            setErrorMessage('Please enter your address')
            setError(true)
            setTimeout(() => {
                setError(false)
            }, 2000);
        } else {
            const location = {};
            location.address1 = pickLocation
            location.address2 = address
            location.country = "United Arab Emirates"
            location.lat = lat
            location.lng = lng
            let data = new FormData();
            data.append('country', location.country);
            data.append('address1', location.address1);
            data.append('latitude', location.lat);
            data.append('longitude', location.lng);
            data.append('address2', location.address2);
            axios
                .post(url + `addresses`, data, configs)
                .then(response => {
                    showLoading(false)
                    console.log(response)
                    props.onAddAddress(response)

                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(false)
                    }, 2000);
                    //  props(rep)
                })
                .catch(error => {
                    console.log(error)
                })
            }

    }

    const [searchBox, setSearchBox] = useState(null);
    const onPlacesChanged = () => {
        var lk = searchBox.getPlaces();
        setDefaultLocation({
            lat: lk[0]
                .geometry
                .location
                .lat(),
            lng: lk[0]
                .geometry
                .location
                .lng()
        });
        setPickLocation(lk[0].formatted_address)
        setLat(lk[0].geometry.location.lat());
        setLng(lk[0].geometry.location.lng());
    }

    const onSBLoad = ref => {
        setSearchBox(ref);
    };

    useEffect(() => {
        function getLocation() {
            if (navigator.geolocation) {
                navigator
                    .geolocation
                    .getCurrentPosition(showPosition);
            }
        }

        function showPosition(position) {
            setDefaultLocation(
                {lat: position.coords.latitude, lng: position.coords.longitude}
            );
        }
        getLocation()
    }, [])

   

    var user = localStorage.getItem('user');
    const key = "AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";
    const lib = ['places'];

    return (
        <div className="w-full md:w-2/3">
           {
                error && <div
                        className="w-screen h-screen fixed top-0 left-0 bg-redLight z-50 flex items-center justify-center">
                        <Error>
                            {errorMessge}
                        </Error>
                    </div>
            }
            <div className="row flex flex-wrap">

                <div className="w-full xl:w-1/2">
                    <div className="w-full  text-left p-4">
                        <label className="text-black mb-4 block text-3xl " htmlFor="Address">
                            Address details</label>
                        <input
                            placeholder=' Address Details'
                            autoComplete="true"
                            id="autoAddress"
                            type="text"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                            className=" border-2 border-mainColor block w-full px-4 py-4 mt-4 text-gray-700  text-2xl rounded-xl"/>
                    </div>
                    <div className="w-full  text-left p-4  ">
                        <label className="text-black mb-4 block text-3xl " htmlFor="Address">Select Your Location</label>
                        <input
                            value={pickLocation}
                            readOnly="readOnly"
                            placeholder='business bay iris bay tower floor 21 office 12'
                            autoComplete="true"
                            id="address"
                            type="text"
                            className="rounded-2xl block w-full mb-4 text-mainColor bg-black text-2xl  "/>
                        <div id="map">

                            <LoadScript googleMapsApiKey={key} libraries={lib}>
                                <div className='relative '>
                                    <img
                                        src={pin}
                                        className="absolute top-1/2 left-1/2 -translate-x-1/2 w-12 z-40"
                                        alt=""/>

                                    <GoogleMap
                                        center={defaultLocation}
                                        zoom={12}
                                        onLoad={handleOnLoad}
                                        onDragEnd={handleCenterChanged}
                                        mapContainerStyle={{
                                            width: '100%',
                                            height: '40rem'
                                        }}>
                                        <StandaloneSearchBox onLoad={onSBLoad} onPlacesChanged={onPlacesChanged}>
                                            <input
                                                id="searchMap"
                                                type="text"
                                                placeholder="Choose your location"
                                                style={{
                                                    boxSizing: `border-box`,
                                                    border: `1px solid #ccc`,
                                                    width: `100%`,
                                                    padding: `20px`,
                                                    paddingLeft: `5px`,
                                                    borderRadius: `0`,
                                                    boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                    fontSize: `14px`,
                                                    outline: `none`,
                                                    textOverflow: `ellipses`,
                                                    position: "absolute",
                                                    left: "0%",
                                                    top: "0rem"
                                                }}/>
                                        </StandaloneSearchBox>
                                    </GoogleMap>
                                </div>
                            </LoadScript>
                            <button
                                onClick={updateFunObj}
                                className='font-medium py-6 border-2 w-full bg-mainColor hover:bg-yellow text-white hover:text-subColor hover:border-yellow transition transition-1000 text-2xl'>
                                Confirm
                            </button>
                        </div>
                    </div>
                </div>
                <div className="w-full xl:w-1/2 px-4">
                    <h3 className="font-bold mt-16 text-2xl text-mainColor mb-4">
                        My Addresses</h3>
                    {
                        allAddresses.map((item, index) => (
                            <div
                                key={index}
                                className="flex items-center justify-between text-2xl border-2 border-mainColor my-4 p-2 py-4 rounded-lg">
                                <p className='text-mainColor'>
                                    {item.address1}</p>
                                <div className="options flex items-center gap-2">
                                    <b className='mx-2 cursor-pointer' onClick={() => handleDelete(item.id)}>
                                        <MdDeleteOutline className="text-red text-3xl "/>
                                    </b>
                                    {/*  <b className='mx-2 cursor-pointer' onClick={(e) => {handleEdit(item.id); set
 * Modal(true)}}> <AiOutlineEdit className="text-green text-3xl " /></b> 
 */
                                    }
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default AddAddress