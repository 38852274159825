import { BsCheckCircle } from 'react-icons/bs'

const Success = (props) => {
  return (
    <div className="alert flex flex-wrap  mx-auto  fixed h-96 z-50  top-1/2 -translate-x-1/2 -translate-y-1/2 left-1/2 bg-white rounded-lg shadow-md">
    <div className="relative w-full overflow-hidden h-96 rounded-lg" >
              <div className="flex items-center justify-start bg-green h-24 px-6 w-full">
                        <BsCheckCircle className='text-white text-4xl' />
                        <p className='text-4xl text-white ml-4'>Alert </p>
              </div>

              <div className=" px-6 py-2  bg-white flex items-center justify-center h-72 w-full  flex-col">
                        <BsCheckCircle className="text-9xl text-green mb-4 " />
                        <span className=" text-green  text-4xl mt-4">Success</span>
                        <p className="text-xl text-green">{props.successMessge}</p>
              </div>
    </div>
</div>
  )
}

export default Success