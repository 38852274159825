import React, { useState } from 'react'
import Layout from '../Component/Layout'
import axios from 'axios'
import { configs, url } from '../Commens/Commens'
import flag from '../img/uae.png'
import hsFlag from '../img/ng.png'
import { useNavigate } from 'react-router-dom';
import { LoadScript } from "@react-google-maps/api";
import { GoogleMap } from '@react-google-maps/api';
import pin from '../img/pin.svg'

const defaultLocation = { lat: 25.2048, lng: 55.2708 };
const key = "AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc";


const Signup = () => {
  const navigate = useNavigate();
  const [address, setAddress] = useState('');
  const [state, setState] = useState(false);
  const [mapref, setMapRef] = useState(null);
  const [lat, setLat] = useState();
  const [lng, setLng] = useState();
  const [phone, setPhone] = useState(false)
  const name = useFormInput('');
  const email = useFormInput('');
  const gender = useFormInput('');
  const [sign, setSign] = useState(false);
  const [otp, setOtp] = useState(false);
  const [number1, setnumber1] = useState('');
  const [number2, setnumber2] = useState('');
  const [number3, setnumber3] = useState('');
  const [number4, setnumber4] = useState('');
  const [number5, setnumber5] = useState('');
  const opt = [number1, number2, number3, number4, number5]
  const [pickLocation, setPickLocation] = useState('');
  const countryJey = localStorage.i18nextLng


  const handleOnLoad = map => {
    setMapRef(map);
  };

  const displayLocation = (latitude, longitude) => {
    var request = new XMLHttpRequest();
    var method = 'GET';
    var url = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latitude + ',' + longitude + '&sensor=true&key=AIzaSyAV8VEG1RLclapyZ92xOujbsX1lRnIksdc';
    var async = true;

    request.open(method, url, async);
    request.onreadystatechange = function () {
      if (request.readyState == 4 && request.status == 200) {
        var data = JSON.parse(request.responseText);
        var address = data.results[0];
        setPickLocation(address.formatted_address)
        //    $('#map-location').html(address.formatted_address);
      }
    };
    request.send();
  };



  const handleCenterChanged = (e) => {
    if (mapref) {
      const newCenter = mapref.getCenter();
      // console.log("lat : " + newCenter.lat(), " lng : " + newCenter.lng());
      setLat(newCenter.lat());
      setLng(newCenter.lng());
      displayLocation(newCenter.lat(), newCenter.lng())
      if (lng === undefined || lat === undefined) {
        setState(false)
      } else {
        setState(true)
      }
    }
  };


  const handleSend = (event) => {
    event.preventDefault()
    let data = new FormData();
    data.append('phone', `${localStorage.i18nextLng == 'ha' ? '+234' : '+971'}${phone}`);
    axios.post(url + 'otp/request', data, configs).then(response => {
      setOtp(true)
    }).catch(error => {
      console.log(error.response)
    })
  }


  const handleVer = (event) => {
    const otpString = opt.join('')
    event.preventDefault()
    let data = new FormData();
    data.append('phone', `${localStorage.i18nextLng == 'ha' ? '+234' : '+971'}${phone}`);
    data.append('otp', otpString);
    // console.log('phone', phone, 'otp', otpString)
    axios.post(url + 'otp/verify', data, configs).then(response => {
      const data = response.data.data
      if (data === null) {
        setSign(true)
      } else {
        localStorage.setItem('token', JSON.stringify(response.data.data.token));
      localStorage.setItem('user', response.data.data.name);
        navigate('/')
        window.location.href ='/'
      }
      setOtp(false)
      setnumber1('')
      setnumber2('')
      setnumber3('')
      setnumber4('')
      setnumber5('')
      // console.log(response)
      // localStorage.setItem('user', JSON.stringify(response.data.data.name));
    }).catch(error => {
      console.log(error)
    })
  }


  const handleSignup = (event) => {
    event.preventDefault()
    let data = new FormData();
    data.append('phone', `${localStorage.i18nextLng == 'ha' ? '+234' : '+971'}${phone}`);
    data.append('email', email.value);
    data.append('gender', gender.value);
    data.append('address1', pickLocation);
    data.append('address2', address);
    data.append('name', name.value);
    data.append('city', 'dubai');
    data.append('latitude', lat);
    data.append('longitude', lng);
    axios.post(url + 'signup', data, configs).then(response => {
      localStorage.setItem('user', response.data.data.name);

      const token = response.data.data.token
      localStorage.setItem('token', JSON.stringify(token));
      navigate('/')
      window.location.href ='/'
    }).catch(error => {
      error = error.response.data.message
      console.log(error)
    })
  }

  return (
    <Layout>
      <div id="Banner" className=' relative  overflow-hidden py-48'>
        <div className="container mx-auto">
          <div className="row flex items-center justify-center px-4 flex-col">
            {!localStorage.token &&
              <>
                {otp ?
                  <div className="w-full md:w-1/2 xl:w-1/3  relative z-30 rounded-xl  md:p-4 md:px-6 border-2 border-mainColor shadow-lg">
                    <div className="otp w-full mt-8 ">
                      <label htmlFor="" className='text-2xl w-full my-4 flex justify-center capitalize text-black font-bold text-center mb-8'>enter the code that was sent to</label>
                      <form className="relative gap-4 flex items-center justify-center" id='otp'>
                        <input type="tel" onChange={(event) => setnumber1(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='shadow-lg p-4  border border-gray w-16 text-2xl text-center font-bold rounded-lg' />
                        <input type="tel" onChange={(event) => setnumber2(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='shadow-lg p-4  border border-gray w-16 text-2xl text-center font-bold rounded-lg' />
                        <input type="tel" onChange={(event) => setnumber3(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='shadow-lg p-4  border border-gray w-16 text-2xl text-center font-bold rounded-lg' />
                        <input type="tel" onChange={(event) => setnumber4(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='shadow-lg p-4  border border-gray w-16 text-2xl text-center font-bold rounded-lg' />
                        <input type="tel" onChange={(event) => setnumber5(event.target.value)} maxLength='1' pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}" className='shadow-lg p-4  border border-gray w-16 text-2xl text-center font-bold rounded-lg' />
                      </form>
                      <div className="flex justify-between mt-6">
                        <button onClick={handleVer} className='bg-mainColor flex p-4 px-8 mx-auto my-4 rounded-xl items-end  text-white font-bold text-2xl'>Continue </button>
                      </div>
                    </div>
                  </div>
                  :
                  (sign ?
                    <div className="w-full md:w-1/2 xl:w-1/3 relative z-30   rounded-xl p-4 md:px-6 border-2 border-mainColor">
                      <h1 className='text-2xl font-bold my-6 text-black'> Sign Up </h1>
                      <div className='form-group my-8 flex flex-wrap '>
                        <label htmlFor="" className='text-2xl flex capitalize text-black'>phone</label>
                        <input value={`${localStorage.i18nextLng == 'ha' ? '+234' : '+971'}${phone}`} disabled placeholder='Full name' autoComplete="true" id="mobile" type="text" className="border-2 py-4 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-lightGray text-2xl cursor-not-allowed " />
                      </div>
                      <div className='form-group my-8 flex flex-wrap '>
                        <label htmlFor="" className='text-2xl flex capitalize text-black'>Full name</label>
                        <input {...name} placeholder='Full name' autoComplete="true" id="mobile" type="text" className="border-2 py-4 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                      </div>
                      <div className='form-group my-8 flex flex-wrap '>
                        <label htmlFor="" className='text-2xl flex capitalize text-black'>email</label>
                        <input {...email} placeholder='Full name' autoComplete="true" id="mobile" type="email" className="border-2 py-4 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                      </div>
                      {/* <div className='form-group my-8 flex flex-wrap '>
                    <label htmlFor="" className='text-2xl flex capitalize text-black'>Full Address</label>
                    <input {...address1} placeholder='Full name' autoComplete="true" id="mobile" type="text" className="border-2 py-4 border-mainColor rounded-xl block w-full p-2 mt-2 text-gray-700 bg-white text-2xl  " />
                  </div> */}
                      <div className="w-full" >
                        <div className="w-full  text-left my-8">
                          <label className="text-2xl flex capitalize text-black " htmlFor="Address">  Address details</label>
                          <input onChange={e => setAddress(e.target.value)} placeholder='business bay iris bay tower floor 21 office 12' autoComplete="true" id="Address" type="text" className="rounded-2xl border-2 border-mainColor block w-full px-4 py-4 mt-2 text-gray-700 bg-white text-3xl  " />
                        </div>
                        <div className="w-full  text-left my-8  ">
                          <label className="text-2xl flex capitalize text-black my-8" htmlFor="Address">Select Your Location</label>
                          <input disabled placeholder='Confirm your location to show your address' autoComplete="true" id="autoAddress" type="text" value={pickLocation} className=" border-2 border-gray block w-full px-4 py-4 mt-4 text-gray-700 bg-gray text-2xl opacity-50 " />
                          <div id="map">
                            <LoadScript googleMapsApiKey={key} >
                              <div className='relative overflow-hidden rounded-lg'>
                                <img src={pin} className="absolute top-1/2 left-1/2 -translate-x-1/2 w-12 z-50" alt="" />
                                <GoogleMap
                                  center={defaultLocation}
                                  zoom={12}
                                  onLoad={handleOnLoad}
                                  onCenterChanged={handleCenterChanged}
                                  mapContainerStyle={{ width: '100%', height: '40rem' }}
                                />

                              </div>
                            </LoadScript>

                          </div>
                        </div>
                      </div>

                      <div className='form-group my-8 flex items-center flex-wrap '>
                        <label htmlFor="" className='text-2xl flex capitalize text-black w-full '>gender</label>
                        <div className="flex justify-between items-center text-xl w-full">
                          <div className="form-group w-full my-4">
                            <label className="radioBox text-xl text-black"> Male
                              <input {...gender} type="radio" name="gender" value='male' />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                          <div className="form-group w-full my-4">
                            <label className="radioBox text-xl text-black"> Female
                              <input {...gender} type="radio" name="gender" value='female' />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                      </div>

                      <button onClick={handleSignup} className='bg-mainColor flex p-4 px-8 mx-auto my-4 rounded-xl items-end  text-white font-bold text-2xl'>Continue </button>
                    </div>
                    :
                    <div className="w-full md:w-1/2 xl:w-1/3 relative z-30 rounded-xl p-4 md:px-6 border-2 border-mainColor shadow-lg">
                      <div className="otp w-full mt-8 flex justify-center text-center flex-col ">
                        <p className='text-2xl py-4 capitalize text-black font-bold'>your mobile number </p>
                        <div className="form-group flex items-center  border border-gray bg-white w-full p-4 rounded-lg text-2xl">
                          <label htmlFor="" className='flex items-center justify-center border-r px-2 gap-4 pr-8 mr-6'> <img className='w-8 mr-2' src={localStorage.i18nextLng == 'ha' ? hsFlag : flag} alt="" /> {localStorage.i18nextLng == 'ha' ? '+234' : '+971'} </label>
                          <input onChange={(e) => setPhone(e.target.value)} minLength='6' maxLength='12' type="tel" placeholder='52 555 1325' required />
                        </div>
                        <button onClick={phone.length > 8 ? handleSend : null} className='bg-mainColor flex p-4 px-8 mx-auto my-4 rounded-xl items-end  text-white font-bold text-2xl'>Continue</button>
                      </div>
                    </div>
                  )

                }
              </>}





          </div>
        </div>
      </div >

    </Layout>
  )
}
const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = event => {
    setValue(event.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}
export default Signup