import React from 'react'

const index = (props) => {
          const classes = 'card md:border-2 md:border-mainColor rounded-3xl p-4 lg:p-8 ' + props.className
          return (
                    <div className={classes}>
                              {props.children}
                    </div>
          )
}

export default index