import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../img/h_logo.png'
import {AiOutlineCloseCircle,   AiOutlineWhatsApp, AiOutlineInstagram } from 'react-icons/ai'
import {  FaTiktok } from 'react-icons/fa'
import { FiFacebook } from 'react-icons/fi'


const Sidebar = (props) => {
    const handleCloseSideBar =() => {
        props.onChangeSideBar(false)
    }
          return (
                    <>
                              <div onClick={handleCloseSideBar} className="overlay fixed bg-white top-0 left-0 w-full shadow-lg h-screen z-50 flex items-center justify-center flex-wrap opacity-60"></div>
                              <sidebar className="fixed bg-mainColor top-0 left-0 w-10/12 shadow-lg h-screen z-50 flex items-center justify-start flex-wrap">
                                <button onClick={handleCloseSideBar} className='absolute top-4 right-4 text-5xl text-white'><AiOutlineCloseCircle /></button>
                                       <img src={logo} alt="" className='w-full px-4'/>
                                        <ul className='pl-4'>
                                            <li className='text-3xl leading-loose text-white mb-4'>
                                                <Link to='/'>Home Page </Link>
                                            </li>
                                            <li className='text-3xl leading-loose text-white mb-4'>
                                                <Link to='/Category/-1'>Services Page </Link>
                                            </li>
                                            <li className='text-3xl leading-loose text-white mb-4'>
                                                <Link to='/About'>About us Page </Link>
                                            </li>
                                            <li className='text-3xl leading-loose text-white mb-4'>
                                                <Link to='/Contact'>Contact Page </Link>
                                            </li>
                                            <li className='text-3xl leading-loose text-white mb-4'>
                                                <Link to='/Privacy'>Privacy Page </Link>
                                            </li>
                                        </ul>
                                        <ul className='w-full flex items-center justify-start px-6'>
                                            <li className='pr-4 text-5xl text-white'>
                                            <a href="http://www.facebook.com"><AiOutlineInstagram /></a> </li>
                                            <li className='pr-4 text-5xl text-white'>
                                            <a href="http://www.facebook.com"><AiOutlineWhatsApp /></a></li>
                                            <li className='pr-4 text-5xl text-white'>
                                            <a href="http://www.facebook.com"><FaTiktok /></a></li>
                                            <li className='pr-4 text-5xl text-white'>
                                            <a href="http://www.facebook.com"><FiFacebook /></a></li>
                                        </ul>
                              </sidebar>
                    </>
          )
}

export default Sidebar