import { Home, About, Contact, Category, ServiceDetails, Signup, Error, ComingSoon, Tip, Profile, ThankYou} from './Pages'
import { Route, BrowserRouter, Routes  } from "react-router-dom";
 
function App() { 
  return (
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<Error />} /> 
          <Route path="/ComingSoon" element={<ComingSoon />} /> 
          <Route path="/ThankYou" element={<ThankYou />} /> 
          <Route path="/" element={<Home />} /> 
          <Route path="/Signup" element={<Signup />} /> 
          <Route path="/Profile" element={<Profile />} /> 
          <Route path="/About" element={<About />} /> 
          <Route path={localStorage.token ? '/Tip' : '*'} element={<Tip />} /> 
          <Route path="/Contact" element={<Contact />} /> 
          <Route path="/Category/:id" element={<Category />} /> 
          <Route path="/ServiceDetails/:id" element={<ServiceDetails />} /> 
        </Routes>
      </BrowserRouter>
  );
}

export default App;
