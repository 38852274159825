import axios from 'axios';
import React from 'react'
import { configs, getBookingObj, getErrorInCard, setBookingObj, showLoading, updateCostCard, url } from '../../Commens/Commens';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

const Frequency = () => {
  const [FrequencyVal, setFrequencyVal] = useState(1)
  const { id } = useParams();
  function loadParamsObj(event) {
    const old = getBookingObj()
    old.category = `${id}`
    old.frequency_id = parseInt(FrequencyVal)
    const data = old
    const configsPost = configs
    configsPost.data = data
    axios.post(url + `calculate_price`, data, configsPost).then(response => {
      if (response.status === 404) { console.log(response); return; }
      if (response.status === 400) { console.log(response); return; }
      if (response.status === 200) {
        const data = response.data.data;
        console.log(data)
        updateCostCard(data)
        showLoading(false)
      }
    }).catch(error => {
      console.log(error.message)
      const data = error;
      getErrorInCard(data)
      showLoading(false)
    })
    setBookingObj(old)
  }

  loadParamsObj()

return (
    <div className="frequancy mt-8" onChange={(e)=> setFrequencyVal(e.target.value)}>
      <h3 className='font-bold text-3xl '>How often do you need your professional?</h3>
      <label className="frequancyItem flex items-center my-16 w-full radioBox ">
        <div className="w-12">
          <input type="radio" name="radio" value='1' readOnly checked={FrequencyVal == 1} />
          <span className="checkmark"></span>
        </div>
        <div className="title w-full">
          <h3 className='text-2xl font-bold '> One-time  </h3>
          <p className='text-2xl my-2'>Book a cleaning for one time only</p>
        </div>
      </label>

      <label className="frequancyItem flex items-center my-16 w-full radioBox ">
        <div className="w-12">
          <input type="radio" name="radio" value='2' />
          <span className="checkmark"></span>
        </div>
        <div className="title w-full">
          <h3 className='text-2xl font-bold '> Bi-weekly  </h3>
          <p className='text-2xl my-2'>Book a cleaning for one time only</p>
        </div>
      </label>

      <label className="frequancyItem flex items-center my-16 w-full radioBox ">
        <div className="w-12">
          <input type="radio" name="radio" value='3' />
          <span className="checkmark"></span>
        </div>
        <div className="title w-full">
          <h3 className='text-2xl font-bold '> Weekly  </h3>
          <p className='text-2xl my-2'>Book a cleaning for one time only</p>
        </div>
      </label>

    </div>
  )
}

export default Frequency